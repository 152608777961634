import React, { memo, useMemo } from "react"
import { Box } from "@chakra-ui/react"

import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"
import { GiftWithPurchaseSwiper } from "@app/components/Cart/GiftWithPurchase/GiftWithPurchaseSwiper"

type Props = {
  isCartPage?: boolean
}

export const GiftWithPurchase: React.FC<Props> = memo(({ isCartPage }) => {
  const { giftWithPurchaseEnabled, giftWithPurchaseProducts } = useGiftWithPurchase(true)

  const isAutoAddProductIncluded = useMemo(() => !!giftWithPurchaseProducts?.find(p => p.autoAdd), [giftWithPurchaseProducts])

  return !isAutoAddProductIncluded && giftWithPurchaseEnabled ? (
    <Box
      id="cart-gwp"
      pt={{ base: "1", sm: isCartPage ? "4.5" : "2" }}
      pb={{ base: "1", sm: isCartPage ? "4.5" : "2" }}
      textAlign="center"
      mb="2"
      width="full"
    >
      <Box maxW="container.sm" mx="auto">
        <Box px={{ base: "2", sm: isCartPage ? "0" : "4" }}>
          {giftWithPurchaseProducts.length && <GiftWithPurchaseSwiper products={giftWithPurchaseProducts} />}
        </Box>
      </Box>
    </Box>
  ) : null
})
