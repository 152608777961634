import { useEffect, useMemo, useState } from "react"
import { useShopify } from "@app/hooks/useShopify"
import { NormalisedProduct } from "@root/types/product"
import { useQuery } from "@apollo/client"
import { GET_PRODUCTS_BY_HANDLE_LIGHT } from "@app/graphql/queries/minimalProduct"
import { useInView } from "react-intersection-observer"

export const useLiveProducts = (products: any, useProductsPlaceholder = true, disabled = false, lazyLoad = false) => {
  const { getHandle, productNormaliser } = useShopify()

  const isAtStartScroll = useMemo(() => (typeof window !== "undefined" ? window.scrollY === 0 : true), [])
  const [ref, inView] = useInView({
    triggerOnce: true,
    initialInView: !isAtStartScroll || !lazyLoad,
    skip: !isAtStartScroll || !lazyLoad,
    rootMargin: "1200px",
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productHandles = useMemo(() => products?.map((item: any) => getHandle(item)).filter(Boolean), [products])

  const { data, loading } = useQuery(GET_PRODUCTS_BY_HANDLE_LIGHT(productHandles?.length ? productHandles : [""]), {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode: "AU",
      handles: [],
      firstCollections: 0,
      firstImages: 2,
      firstMedia: 0,
      firstMetafields: 0,
      firstVariants: 1,
    },
    skip: disabled || !productHandles.length || lazyLoad ? !inView : false,
  })

  const [liveProducts, setLiveProducts] = useState<NormalisedProduct[]>(
    data
      ? products
          ?.map((product: any, index: number) => {
            const normalisedLiveProduct = productNormaliser(data[`product${productHandles?.[index]?.replace(/-/g, "")}`])
            return normalisedLiveProduct?.handle
              ? {
                  ...normalisedLiveProduct,
                  primaryCollection: product?.primaryCollection,
                  siblings: product?.siblings,
                }
              : null
          })
          ?.filter(Boolean)
      : useProductsPlaceholder
      ? products
      : []
  )

  useEffect(() => {
    if (data) {
      const dict = data
      setLiveProducts(
        products
          ?.map((product: any, index: number) => {
            const normalisedLiveProduct = productNormaliser(dict[`product${productHandles?.[index]?.replace(/-/g, "")}`])
            return normalisedLiveProduct?.handle
              ? {
                  ...normalisedLiveProduct,
                  primaryCollection: product?.primaryCollection,
                  siblings: product?.siblings,
                }
              : null
          })
          ?.filter(Boolean)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    liveProducts: liveProducts?.length ? liveProducts : [],
    loading,
    inViewRef: ref,
  }
}
