import React, { memo } from "react"
import { Box, Button, Flex } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Navigation } from "swiper"
import "swiper/css/navigation"

import { GiftWithPurchaseProduct } from "@app/components/Cart/GiftWithPurchase/GiftWithPurchaseProduct"
import Icon from "@app/components/Icon"

import "swiper/css"

type Props = {
  products: any[]
}

export const GiftWithPurchaseSwiper: React.FC<Props> = memo(({ products }) => (
  <Box pos="relative" maxWidth={"100%"} mx="auto" mt={2}>
    <Flex direction="row" justifyContent="flex-end" mb="20px">
      <Flex
        justifyContent="space-between"
        width="30px"
        position="relative"
        alignItems="center"
        sx={{
          ".gwp-swiper-button-next::after, .gwp-swiper-button-prev::after": {
            content: '""',
          },
        }}
      >
        <Button className="gwp-swiper-button-prev" top="unset" marginTop="unset">
          <Icon name="carouselChevronLeft" h="14px" w="14px" />
        </Button>

        <Button className="gwp-swiper-button-next" top="unset" marginTop="unset">
          <Icon name="carouselChevronRight" h="14px" w="14px" />
        </Button>
      </Flex>
    </Flex>

    <Box
      borderWidth="1px"
      borderRadius="10px"
      borderColor="#D2D2D4"
      padding={{ base: "8px 20px", md: "12px 24px" }}
      sx={{
        ".swiper-slide.swiper-slide-active": {
          opacity: "1 !important",
          transition: "opacity .3s",
        },
        ".swiper-slide": {
          opacity: "0 !important",
        },
      }}
    >
      <Swiper
        lazy
        modules={[EffectFade, Navigation]}
        effect="fade"
        simulateTouch={false}
        shortSwipes={false}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".gwp-swiper-button-next",
          prevEl: ".gwp-swiper-button-prev",
          disabledClass: "swiper-disabled",
        }}
      >
        {products.map((p: any, i: number) => (
          <SwiperSlide key={`gwp-product-${i}`}>
            <GiftWithPurchaseProduct product={p} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  </Box>
))
